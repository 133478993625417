<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <b-card>
      <validation-observer ref="createForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          key="createForm"
          @submit="submit"
          :form="$refs.createForm"
          :disabled="invalid || checkUsernameLoading"
        >
          <n-input :fields="fields" v-model="data" :initValue="initData">
            <template #lotterySettings="item">
              <lottery-setting-form
                :field="item.field"
                v-model="data.lotterySettings"
              />
            </template>
            <template #username="item">
              <b-row>
                <b-col :sm="6">
                  <b-form-group :label-for="item.field.key">
                    <validation-provider
                      #default="{ errors }"
                      :vid="item.field.key"
                      :name="$t(item.field.label)"
                      :rules="item.field.rules"
                    >
                      <label>
                        {{ $t(item.field.label) }}
                      </label>
                      <input
                        type="hidden"
                        v-model="data[item.field.key]"
                        :name="item.field.key"
                      />
                      <b-row>
                        <n-single-select
                          v-model="username.first"
                          :options="alphabet"
                          :clearable="false"
                          :loading="checkUsernameLoading"
                          class="col-6"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        ></n-single-select>
                        <n-single-select
                          v-model="username.second"
                          :options="alphabet"
                          :clearable="true"
                          :loading="checkUsernameLoading"
                          class="col-6"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        ></n-single-select>
                      </b-row>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                :loading="loading"
                :disabled="checkUsernameLoading"
                v-if="$can('create', 'company')"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BSpinner,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NSingleSelect from "@/components/NSingleSelect";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import Alphabet from "@/data/alphabet.js";
import LotterySettingForm from "./LotterySettingForm";

const CompanyRepository = Repository.get("company");
const LotteryRepository = Repository.get("lottery");

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    BSpinner,
    NSingleSelect,
    LotterySettingForm,
  },
  directives: {
    Ripple,
  },
  watch: {
    "username.first": function (value) {
      this.data.username = `${this.username.first}${
        this.username.second ? this.username.second : ""
      }`;
    },
    "username.second": function (value) {
      if (this.username.first) {
        this.data.username = `${this.username.first}${
          this.username.second ? this.username.second : ""
        }`;
      }
    },
    "data.username": function (value) {
      if (value) {
        this.checkUsername(value);
      }
    },
  },
  data() {
    return {
      data: {
        name: null,
        phone: null,
        username: null,
        password: null,
        commission: 0,
        share: 0,
        ccy: null,
        lotteryIds: [],
        lotterySettings: [],
        isEnable: true,
        isReadonly: false,
        commissionNumTwo: 0,
        commissionNumThree: 0,
        maxWinDay: 0,
        maxTodayBet: 0,
        minBetInvoice: 0,
        maxBetInvoice: 0,
        minBetGame: 0,
        maxBetGame: 0,
        minBetPerNumTwo: 0,
        maxBetPerNumTwo: 0,
        minBetPerNumThree: 0,
        maxBetPerNumThree: 0,
      },
      initData: {},
      loading: false,
      checkUsernameLoading: false,
      usernameValid: false,
      username: {
        first: null,
        second: null,
      },
    };
  },
  mounted() {
    this.getSuggestUsername();
    if (this.$route.query.id) {
      this.show();
    } else {
      this.getLottery();
    }
  },
  methods: {
    getLottery() {
      LotteryRepository.listActive().then((response) => {
        const data = response?.data?.data;
        if (data) {
          data.forEach((element) => {
            this.data.lotterySettings.push({
              lotteryName: element.name,
              lotteryNameKh: element.nameKh,
              lotteryId: element.id,
              payoutTwoDigit: null,
              payoutThreeDigit: null,
              commissionTypeThree: 1,
              commissionTypeTwo: 1,
              commissionNumTwo: null,
              commissionNumThree: null,
            });
          });
        }
      });
    },
    show() {
      CompanyRepository.show(this.$route.query.id).then((response) => {
        delete response.data.data.username;

        this.initData = {
          ...response.data.data,
          lotterySettings: this.data.lotterySettings,
        };
      });
    },
    submit() {
      if (!this.usernameValid) {
        return;
      }
      this.$refs.createForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          CompanyRepository.create(this.data)
            .then((response) => {
              this.$router.push({
                name: "view-company",
                params: { id: response.data.data.id },
              });
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.createForm.setErrors(error.response?.data?.errors);
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
    getSuggestUsername() {
      this.checkUsernameLoading = true;
      CompanyRepository.getSuggestUsername()
        .then((response) => {
          const username = response.data?.data?.username;
          if (username) {
            this.username.first = username.charAt(0);
            this.username.second = username.charAt(1);
          }
        })
        .catch(() => {
          this.checkUsernameLoading = false;
        })
        .then(() => {
          this.checkUsernameLoading = false;
        });
    },
    checkUsername(username) {
      this.checkUsernameLoading = true;
      CompanyRepository.checkUsername(username)
        .then((response) => {
          if (response.data?.data?.used) {
            this.$refs.createForm.setErrors({
              username: this.$t("validation.unique", {
                field: this.$t("field.username"),
              }),
            });
            this.usernameValid = false;
          } else {
            this.usernameValid = true;
          }
        })
        .catch()
        .then(() => {
          this.checkUsernameLoading = false;
        });
    },
    back() {
      this.$router.back();
    },
  },
  setup() {
    const fields = FormInput.map((value) => {
      if (value.key === "password" || value.key === "retypePassword") {
        value.rules = value.rules.replace("required|", "");
        value.rules = value.rules.replace("required", "");
        value.rules = value.rules.replace("", "required|");
      } else if (value.key === "givenCredit") {
        value.disabled = false;
      }

      return value;
    });

    const alphabet = [...Alphabet];
    return { fields, alphabet };
  },
};
</script>
